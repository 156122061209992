<template>
  <div class="app">
    <splash-screen v-show="isLoading" />
    <router-view v-if="!isLoading"></router-view>
  </div>
</template>
<script>
import SplashScreen from './components/SplashScreen.vue'

export default {
  name: "App",
  components: {
    SplashScreen
  },
  data() {
    return {
      isLoading: true,
      isMobile: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 1500)
  }
};
</script>

<style lang="scss">
@import "./assets/css/global-styles.scss";

* {
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $white;
  background-color: $black;
  min-height: 100vh;
  min-width: 100vw;
  font-size: $font-size;
  overflow-x: hidden;
  @include geo-font;
}

@include phone {
  splash-screen {
    display: none;
  }
}
</style>
<template>
  <figure class="logo__wrapper">
    <img src="../assets/grid-logo.svg" alt="Grid Logo" />
  </figure>
</template>
<script>
export default {
  name: "Logo",
};
</script>
<style lang="scss" scoped>
@import "../assets/css/global-styles.scss";

.logo__wrapper {
  width: 90%;
  
  @include desktop {
    height: 250px;
    width: 250px;
  }

  & img {
    padding: 20px;
    background-color: $black;

    @include desktop {
      border: solid 1px $dark-gray;
    }
  }

  & p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    font-size: $font-size-medium;
  }
}
</style>

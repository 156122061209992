<template>
  <div class="splash-screen">
    <logo class="splash-screen__logo"/>
  </div>
</template>
<script>
import Logo from '../components/Logo.vue'

export default {
  name: 'SplashScreen',
  components: {
    Logo
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/css/global-styles.scss";

.splash-screen {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include page-animation;

  &__logo {

    @include desktop {
      position: absolute;
      top: 50vh;
      transform: translateY(-50%);
      margin: 0;
    }
  }
}
</style>
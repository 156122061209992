<template>
  <div class="landing-page">
    <logo class="landing-page__logo"/>
    <div class="landing-page__links">
      <router-link class="landing-page__link" to="about"
        ><p class="landing-page__link-text">
          About<span class="arrow">&rarr;</span>
        </p></router-link
      >
      <router-link class="landing-page__link" to="connect"
        ><p class="landing-page__link-text">
          Connect<span class="arrow">&rarr;</span>
        </p></router-link
      >
      <router-link class="landing-page__link" to="work"
        ><p class="landing-page__link-text">
          Featured Project<span class="arrow">&rarr;</span>
        </p></router-link
      >
      <router-link class="landing-page__link" to="/"
        ><p class="landing-page__link-text">Coming soon...</p></router-link
      >
    </div>
    <logo class="landing-page__icon" />
    <router-view></router-view>
  </div>
</template>
<script>
import Logo from "../components/Logo.vue";

export default {
  name: "LandingPage",
  components: {
    Logo,
  },
  data() {
    return {
      isActive: true
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/css/global-styles.scss";

.arrow {
  margin-left: 20px;
}

.active {
  @include text-gradient;
}

.landing-page {
  position: relative;

  &__logo {
    margin: 20px auto;

    @include desktop {
      display: none;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    @include page-animation;
  }

  &__link {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: $black;
    color: $white;
    outline: 1px solid $dark-gray;
    cursor: pointer;
    @include geo-font;
    font-weight: bold;
    font-size: 62px;
    text-decoration: none;
    transition: .6s background-color;

    &:hover {
        background-color: $light-gray;
      }

    &-text {
      color: $blue;
      transition: 1s all;
      @include text-gradient;
    }

    @include desktop {
      max-width: 50vw;
      height: 50vh;
    }
  }

  &__icon {
    @include phone {
      display: none;
    }
    
    @include desktop {
      position: relative;
      top: 50vh;
      transform: translateY(-50%);
      margin: 0 auto;
    }
  }
}
</style>
